import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import QuizRedirect from './components/QuizRedirect';
import HomePage from './pages/HomePage';
import QuizPage from './pages/QuizPage';
import QuizListPage from './pages/QuizListPage';
import ProfilePage from './pages/ProfilePage';
import Categories from './components/Categories';
import GlobalStyle from './GlobalStyle';
import styled from 'styled-components';



const SidebarToggle = styled.button`
  display: block;
  margin: 20px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const App = () => {
  const [sidebarActive, setSidebarActive] = useState(false);

  const toggleSidebar = () => {
    setSidebarActive(!sidebarActive);
  };

  return (
    <Router>
      <GlobalStyle />
      <div className="container">
        <div className="ad-side">
          <p>Ad space</p>
        </div>
        <div className="content-wrapper">
          <Header />
          {/* <SidebarToggle onClick={toggleSidebar}>
            {sidebarActive ? 'Close Sidebar' : 'Open Sidebar'}
          </SidebarToggle> */}
          <div className="main-content">
            {/* <div className={`sidebar ${sidebarActive ? 'active' : ''}`}>
              <Categories />
            </div> */}
            <main>
              <Routes>
                <Route path="/" exact element={<HomePage />} />
                <Route path="/today-quiz" element={<QuizRedirect />} /> {/* Redirect to latest set */}
                <Route path="/quizzes/*" element={<QuizPage />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/quizzes" element={<QuizListPage />} />
              </Routes>
            </main>
          </div>
          <Footer />
        </div>
        <div className="ad-side">
          <p>Ad space</p>
        </div>
      </div>
    </Router>
  );
};

export default App;
