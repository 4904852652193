import React from 'react';
import UserProfile from '../components/UserProfile';
import GoogleAd from '../components/GoogleAd';

const ProfilePage = () => {
    // Fetch user data (for simplicity, using a static example here)
    const user = { name: 'John Doe', email: 'john@example.com', joinedDate: '2024-01-01' };

    return (
        <div>
            <GoogleAd />
            <UserProfile user={user} />
            <GoogleAd />
        </div>
    );
};

export default ProfilePage;
