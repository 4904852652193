// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './locales/en/translation.json';
import hiTranslations from './locales/hi/translation.json';
import knTranslations from './locales/kn/translation.json';
import mrTranslations from './locales/mr/translation.json';

// Initialize i18n
i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: enTranslations },
      hi: { translation: hiTranslations },
      kn: { translation: knTranslations },
      mr: { translation: mrTranslations },
    },
    lng: 'en', // Default language
    fallbackLng: 'en', // Use English if the user's language is not available
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
  });

export default i18n;
