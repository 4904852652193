import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ensureLanguageSupport, getRootAtThePath, addAvailableLanguages, getPathsToAllLeaves } from '../utils/MetadataUtil2';

const useMetadata = () => {
  const [metadata, setMetadata] = useState();
  const [filteredMetadata, setFilteredMetadata] = useState();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetch("https://d1iacl6c6eifzv.cloudfront.net/metadata.json")
      .then(response => response.json())
      .then(data => {
        addAvailableLanguages(data);
        setMetadata(data);
      })
      .catch(error => console.error('Error loading quiz data:', error));
  }, []);

  useEffect(() => {
    if (!metadata) return;
    let clonedMetadata = JSON.parse(JSON.stringify(metadata));
    ensureLanguageSupport(clonedMetadata, i18n.language);
    setFilteredMetadata(clonedMetadata);
  }, [i18n.language, metadata]);

  return { metadata, filteredMetadata };
};

export default useMetadata;
