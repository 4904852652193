// src/components/Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

// Styled components
const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const TopNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: ${({ theme }) => theme.colors.card};
`;

const Logo = styled.div`
  font-size: 1.98rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.heading};
`;

const SearchBar = styled.input`
  padding: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  width: 300px;
  font-size: 1.1rem;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};

  button {
    margin-left: 1rem;
    padding: 0.55rem 1.1rem;
    border: none;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.accent};
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const BottomNav = styled.nav`
  display: flex;
  justify-content: start;
  background-color: ${({ theme }) => theme.colors.secondary};
  padding: 0.55rem 0.9rem;
`;

const NavLink = styled(Link)`
  margin: 0 1.1rem;
  color: ${({ theme }) => theme.colors.card};
  font-size: 1.21rem;
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

// Header Component
const Header = () => {
  const { t } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Example state for login status

  const handleLoginLogout = () => {
    setIsLoggedIn(prevState => !prevState);
  };

  return (
    <HeaderWrapper>
      <TopNav>
        <Logo>{t('header')}</Logo>
        {/* <SearchBar type="text" placeholder={t('searchPlaceholder')} /> */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <LanguageSelector />
          {/* <UserInfo>
            <span>{isLoggedIn ? 'User Name' : 'Guest'}</span>
            <button onClick={handleLoginLogout}>
              {isLoggedIn ? t('logout') : t('login')}
            </button>
          </UserInfo> */}
        </div>
      </TopNav>
      <BottomNav>
        <NavLink to="/">{t('home')}</NavLink>
        <NavLink to="/today-quiz">{t('todaysQuiz')}</NavLink>
        {/* <NavLink to="/today-news">{t('todaysNews')}</NavLink>
        <NavLink to="/quizzes">{t('quizzes')}</NavLink>
        <NavLink to="/current-affairs">{t('currentAffairs')}</NavLink>
        <NavLink to="/all-india-government-exams">{t('governmentExams')}</NavLink>
        <NavLink to="/all-india-government-jobs">{t('governmentJobs')}</NavLink>
        <NavLink to="/upsc">{t('upsc')}</NavLink>
        <NavLink to="/job-alert">{t('jobAlert')}</NavLink> */}
      </BottomNav>
    </HeaderWrapper>
  );
};

export default Header;
