// src/theme.js
export const theme = {
    colors: {
      primary: '#1e3a8a',       // Deep blue for primary actions
      secondary: '#3b82f6',     // Lighter blue for highlights
      background: '#f9fafb',    // Very light gray background
      text: '#111827',          // Dark gray for text
      accent: '#ef4444',        // Red for accents and notifications
      border: '#d1d5db',        // Light gray for borders
      card: '#ffffff',          // White for cards and containers
    },
    fonts: {
      main: 'Montserrat, sans-serif',  // Modern sans-serif font
      heading: 'Lora, serif',           // Stylish serif font for headings
    },
    fontSizes: {
      main: '1.1rem',                   // Increased base font size by 10%
      heading: '2rem',                  // Increased heading font size by 10%
    }
  };
  