import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FaQuestionCircle } from 'react-icons/fa';
import Confetti from 'react-confetti';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '../hooks/useLocalStorage';

const QuizContainer = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const Icon = styled(FaQuestionCircle)`
  color: #3b82f6;
  margin-right: 1rem;
  font-size: 2rem;
`;

const QuizTitle = styled.h2`
  color: #3b82f6;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-decoration: underline;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
`;

const Question = styled.h3`
  margin-bottom: 1rem;
`;

const Option = styled.label`
  display: block;
  margin: 0.5rem 0;
  cursor: pointer;

  &.correct {
    background-color: #00D100;
    color: #155724;
    border-radius: 8px;
    font-weight: bold;
    padding: 3px;
  }

  &.incorrect {
    background-color: #f8d7da;
    color: #721c24;
    border-radius: 8px;
    padding: 3px;
  }
`;

const SubmitButton = styled.button`
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const Result = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
`;

const Quiz = ({ path }) => {
  const [quizData, setQuizData] = useState([]);
  const [answers, setAnswers] = useLocalStorage(path+"answers", {});
  const [score, setScore] = useLocalStorage(path+"score", null);

  const [showConfetti, setShowConfetti] = useState(false);
  const [confettiSource, setConfettiSource] = useState({ x: 0, y: 0 });
  const submitButtonRef = useRef(null);
  const { t } = useTranslation();
  // Load quiz data based on path
  useEffect(() => {
    fetch("https://d1iacl6c6eifzv.cloudfront.net/data/"+path+"/quizData.json")
    .then(response => response.json())
    .then(data => setQuizData(data.quizData))
    .catch(error => console.error('Error loading quiz data:', error));
  }, [path]);

  const handleChange = (questionIndex, option) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionIndex]: option,
    }));
  };

  const handleSubmit = () => {
    let totalScore = 0;
    quizData.forEach((question, index) => {
      if (answers[index] === question.answer) {
        totalScore++;
      }
    });
    setScore(totalScore);
    setShowConfetti(true);

    // Stop confetti after 4 seconds
    setTimeout(() => {
      setShowConfetti(false);
    }, 4000);
  };

  useEffect(() => {
    if (submitButtonRef.current) {
      const { x, y, width } = submitButtonRef.current.getBoundingClientRect();
      setConfettiSource({ x: x + width / 2, y: y + window.scrollY });
    }
  }, [score]);

  const getTitle = (path) => {
    const parts = path.split('/');
    let title = "";
    for(let i = 0; i < parts.length-2; i++) {
      title = title + t(parts[i]) + " : ";
    }
    title = title + t(`sets`, { number: parts[parts.length - 2].replace('set', '') });
    return title;
  };

  const title = getTitle(path);


  return (
    <QuizContainer>
      {title && (
        <TitleContainer>
          <QuizTitle>{title}</QuizTitle>
        </TitleContainer>
      )}
      {quizData.map((question, index) => (
        <div key={index}>
          <Question>{question.question}</Question>
          {question.options.map((option) => (
            <Option
              key={option}
              className={
                score !== null
                  ? option === question.answer
                    ? 'correct'
                    : answers[index] === option
                    ? 'incorrect'
                    : ''
                  : ''
              }
            >
              <input
                type="radio"
                name={`question-${index}`}
                value={option}
                checked={answers[index] === option}
                onChange={() => handleChange(index, option)}
                disabled={score !== null}
              />
              {option}
            </Option>
          ))}
        </div>
      ))}
      <SubmitButton ref={submitButtonRef} onClick={handleSubmit} disabled={score !== null}>
        Submit
      </SubmitButton>
      {score !== null && (
        <Result>
          Your score is {score} out of {quizData.length}.
        </Result>
      )}
      {showConfetti && (
        <Confetti
          numberOfPieces={200}
          gravity={0.3}
          initialVelocityY={10}
          confettiSource={confettiSource}
        />
      )}
    </QuizContainer>
  );
};

export default React.memo(Quiz);
