import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useMetadata from '../hooks/useMetadata';
import { useTranslation } from 'react-i18next';
import { ensureLanguageSupport, getRootAtThePath, addAvailableLanguages, getPathsToAllLeaves } from '../utils/MetadataUtil2';


const QuizRedirect = () => {
  const navigate = useNavigate();
  const { metadata, filteredMetadata } = useMetadata();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if(filteredMetadata) {
      let paths = getPathsToAllLeaves(filteredMetadata, 'current-affairs');
      let path = paths[0]+"/"+i18n.language;
      navigate(`/quizzes/${path}`);
    }

  }, [filteredMetadata]);

  return null; // This component doesn't render anything visible
};

export default QuizRedirect;
