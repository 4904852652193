import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 20px;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; /* Center content */
    padding: 0 10px; /* Reduce side margins */

    @media (min-width: 768px) {
      margin: 0 10px;
    }
  }

  .main-content {
    display: flex;
    flex-direction: column;
    margin-top: 20px; /* Adjust space between header and content */

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .ad-side {
    display: none;

    @media (min-width: 1024px) {
      width: 150px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 1000; /* Ensure the sidebar is on top */

    &.active {
      transform: translateX(0);
    }

    @media (min-width: 768px) {
      position: static;
      transform: translateX(0);
      box-shadow: none;
      height: auto;
      width: 250px;
    }
  }

  main {
    flex-grow: 1;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    font-size: 24px;
    padding: 10px;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999; /* Just below the sidebar */
    display: none;
  }

  .overlay.active {
    display: block;
  }
`;

export default GlobalStyle;
