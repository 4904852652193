import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ensureLanguageSupport, getRootAtThePath, addAvailableLanguages } from '../utils/MetadataUtil2';

const QuizList = () => {
  const [metadata, setMetadata] = useState();
  const [currentPath, setCurrentPath] = useState([]);
  const [currentRoot, setCurrentRoot] = useState([]);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();





  useEffect(() => {
    // const dummy = {
    //   "children": {
    //     "current-affairs": {
    //       "children": {
    //         "set1": {
    //           "availableLanguages": [
    //             "en",
    //             "hi"
    //           ]
    //         }
    //       }
    //     },
    //     "general-knowledge": {
    //       "children": {
    //         "set1": {
    //           "availableLanguages": [
    //             "en",
    //             "hi"
    //           ]
    //         }
    //       }
    //     }
    //   }
    // };

    // addAvailableLanguages(dummy);
    // setMetadata(dummy);
    
    fetch("https://d1iacl6c6eifzv.cloudfront.net/metadata.json")
    .then(response => response.json())
    .then(data => {
      addAvailableLanguages(data);
      setMetadata(data);
    })
    .catch(error => console.error('Error loading quiz data:', error));
  }, []);

  useEffect(() => {

    if(!metadata) return;
    let clonedMetadata = JSON.parse(JSON.stringify(metadata));
    ensureLanguageSupport(clonedMetadata, i18n.language);
    setCurrentRoot(clonedMetadata);
  }, [i18n.language, metadata])

  const handleSelection = (key) => {
    if(currentRoot.children[key].children) {
      setCurrentPath(prev => [...prev, key]);
      setCurrentRoot(currentRoot.children[key]);
    } else {
      const path = `/quizzes/${currentPath.join('/')}/${key}/${i18n.language}`;
      navigate(path);
    }
  };

  const handleBackPress = () => {
    if(currentPath.length > 0) {
      const newPath = currentPath.slice(0, -1);
      setCurrentPath(newPath);
      setCurrentRoot(getRootAtThePath(currentRoot, newPath));
    }
  };



  return (
    <div>
      <h2>Available Quizzes</h2>
      <ul>
      {currentRoot && currentRoot.children && Object.keys(currentRoot.children).map(key => {
          
          return (
            <li 
            style={{
              cursor: 'pointer',
              padding: '8px 8px',
              backgroundColor: '#f0f0f0',
              borderRadius: '4px',
              transition: 'background-color 0.3s ease',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e0e0e0')}
            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
            key={key} onClick={() => handleSelection(key)}>
                {key}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default QuizList;
