import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faNewspaper, faBook, faFootballBall, faFilm, faLandmark, faHistory, faAtom, faUser, faMusic, faBrain, faAtlas } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { ensureLanguageSupport, getRootAtThePath, addAvailableLanguages, getPathsToAllLeaves } from '../utils/MetadataUtil2';
import useMetadata from '../hooks/useMetadata'

const CategoriesWrapper = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  position: relative;
  text-align: center;
  top: 20px;
`;

const CategoryTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
  background: #f1f1f1;
`;

const CategoryList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const CategoryItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 18px;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? 'blue' : 'inherit')};
  background: ${({ isActive }) => (isActive ? '#f1f1f1' : 'inherit')};
  padding: ${({ isActive }) => (isActive ? '5px' : 'inherit')};
  text-decoration: ${({ isActive }) => (isActive ? 'underline' : 'none')};

  & > svg {
    margin-right: 8px;
  }
`;

const categories = [
  { name: 'Current Affairs', path: 'current-affairs', icon: faNewspaper },
  { name: 'General Knowledge', path: 'general-knowledge', icon: faBook },
  { name: 'UPSC', path: 'upsc', icon: faAtlas },
  { name: 'Indian Mythology', path: 'indian-mythology', icon: faHistory },
  { name: 'Cricket', path: 'cricket', icon: faFootballBall },
  { name: 'Bollywood', path: 'bollywood', icon: faFilm },
  { name: 'Indian Politics', path: 'indian-politics', icon: faLandmark },
  { name: 'Indian History', path: 'indian-history', icon: faHistory },
  { name: 'Indian Geography', path: 'indian-geography', icon: faGlobe },
  { name: 'Science', path: 'science', icon: faAtom },
  { name: 'Sports', path: 'sports', icon: faFootballBall },
  { name: 'Famous Personalities', path: 'famous-personalities', icon: faUser },
  { name: 'Culture Music Food', path: 'culture-music-food-and-traditions', icon: faMusic },
  { name: 'Logic and Reasoning', path: 'aptitude-and-Reasoning', icon: faBrain },
  { name: 'World History', path: 'world-history', icon: faAtlas },
];


const CategoryLink = ({ category, isActive, onClick }) => {

  return (
    <CategoryItem isActive={isActive} onClick={() => onClick(category.path)}>
      <FontAwesomeIcon icon={category.icon} />
      {category.name}
    </CategoryItem>
  );
};

const Categories = () => {
  const [activeCategory, setActiveCategory] = useState(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { metadata, filteredMetadata } = useMetadata();

  const getRandomSet = (filteredMetadata, categoryPath) => {
    let paths = getPathsToAllLeaves(filteredMetadata, categoryPath);
    paths = paths.map(path => path+"/"+i18n.language);
    let unFinishedPath;
    for(let path of paths) {
      if(!localStorage.getItem(path+"score")) {
        unFinishedPath = path;
        break;
      }
    }
    if(!unFinishedPath) {
      unFinishedPath = paths[0];
    }
    
    return unFinishedPath;
  };

  const handleClick = (categoryPath) => {
    if(filteredMetadata.children[categoryPath]) {
      const randomSet = getRandomSet(filteredMetadata, categoryPath);
      setActiveCategory(categoryPath);
      navigate(`/quizzes/${randomSet}`);
    } else {
      alert('no quiz available for this category yet.');
    }
    
  };

  return (
    <CategoriesWrapper>
      <CategoryTitle>Instant Quiz</CategoryTitle>
      <CategoryList>
        {categories.map((category, index) => (
          <CategoryLink
            key={index}
            category={category}
            isActive={category.path === activeCategory}
            onClick={handleClick}
          />
        ))}
      </CategoryList>
    </CategoriesWrapper>
  );
};

export default Categories;
