import React from 'react';
import QuizList from '../components/QuizList';
import GoogleAd from '../components/GoogleAd';

const HomePage = () => {
    return (
        <div>
            <GoogleAd />
            <QuizList />
            <GoogleAd />
        </div>
    );
};

export default HomePage;
