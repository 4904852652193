// src/components/GoogleAd.js
import React, { useEffect } from 'react';

const GoogleAd = () => {
  useEffect(() => {
    if (window) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);

  return (
    <div style={{ textAlign: 'center', margin: '1rem 0' }}>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-XXXXXX" // Replace with your AdSense client ID
        data-ad-slot="XXXXXX" // Replace with your AdSense ad slot ID
        data-ad-format="auto"
      />
    </div>
  );
};

export default GoogleAd;
