import React, { useState } from 'react';
import styled from 'styled-components';
import { FaGlobe } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const LanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  cursor: pointer;
`;

const LanguageIcon = styled(FaGlobe)`
  font-size: 1.3rem;
  margin-right: 0.5rem;
`;

const LanguageText = styled.div`
  font-size: 0.9rem;
  margin-right: 0.5rem;
`;

const LanguageMenu = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  right: 0;
  background-color: ${({ theme }) => theme.colors.card};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 120px;
  padding: 0.5rem 0;

  div {
    padding: 0.5rem 1rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.text};
    transition: background-color 0.3s ease;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
      color: #fff;
    }
  }
`;

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    setIsOpen(false); // Close menu after language change
    if(location.pathname.includes('/quizzes')) {
      navigate('/'); // Navigate to home page
    }
  };

  const toggleMenu = (e) => {
    e.stopPropagation(); // Prevent closing the menu on click inside
    setIsOpen(!isOpen);
  };

  // Close menu when clicking outside of it
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest('.language-wrapper') === null) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <LanguageWrapper className="language-wrapper" onClick={toggleMenu}>
      <LanguageIcon />
      <LanguageText>{t('selectLanguage')}</LanguageText>
      <LanguageMenu isOpen={isOpen}>
        <div onClick={() => handleLanguageChange('en')}>English</div>
        <div onClick={() => handleLanguageChange('hi')}>Hindi</div>
        <div onClick={() => handleLanguageChange('kn')}>Kannada</div>
        <div onClick={() => handleLanguageChange('mr')}>Marathi</div>
      </LanguageMenu>
    </LanguageWrapper>
  );
};

export default LanguageSelector;
