import React from 'react';
import { useLocation } from 'react-router-dom';
import Quiz from '../components/Quiz';
import GoogleAd from '../components/GoogleAd';

const QuizPage = () => {
const location = useLocation();
  const fullPath = location.pathname.replace('/quizzes/', ''); 
  // Fetch quiz data based on ID (for simplicity, using a static example here)
  const quiz = { title: 'Sample Quiz', description: 'This is a sample quiz.' };
  console.log("QuizPage renders")
  return (
    <div>
      <GoogleAd />
      <Quiz path={fullPath} />
      <GoogleAd />
    </div>
  );
};

export default React.memo(QuizPage);
