
export const addAvailableLanguages = (currentNode) => {
    if (currentNode.availableLanguages) {
      return currentNode.availableLanguages;
    }

    if (currentNode.children) {
      let a = [];
      Object.keys(currentNode.children).forEach(key => {
        a = [...new Set([...a, ...addAvailableLanguages(currentNode.children[key])])];
      });
      currentNode.availableLanguages = a;
      return a;
    }
    return [];
  };

 export const hasChildrenInLanguage = (currentNode, language) => {
    return currentNode.availableLanguages.includes(language);
  };


 export const ensureLanguageSupport = (currentNode, language) => {
    if(!currentNode.children) {
      return;
    }
    Object.keys(currentNode.children).forEach(key => {
      if (!hasChildrenInLanguage(currentNode.children[key], language)) {
        console.log(currentNode);

        delete currentNode.children[key];
      } else {
        ensureLanguageSupport(currentNode.children[key], language);
      }
    });
  }

  export const getRootAtThePath = (root, path) => {
    if (path.length === 0) {
      return root;
    }
    return getRootAtThePath(root.children[path[0]], path.slice(1));
  }

  export const getPathsToAllLeaves = (root, immediateChild) => {
    const paths = [];
    const traverse = (node, path) => {
      if (!node.children) {
        paths.push(path);
        return;
      }
      Object.keys(node.children).forEach(key => {
        traverse(node.children[key], path +"/"+key);
      });
    };
    traverse(root.children[immediateChild], immediateChild);
    return paths;
  }