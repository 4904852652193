import React from 'react';

const UserProfile = ({ user }) => {
  return (
    <div>
      <h2>{user.name}'s Profile</h2>
      <p>Email: {user.email}</p>
      <p>Joined: {user.joinedDate}</p>
      {/* Display user statistics here */}
    </div>
  );
};

export default UserProfile;
